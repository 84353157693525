import React from 'react';
import Header from './Header';
import Home from './Home';
import Converter from './Converter';
// import BulkImport from './bulkimport';
import Footer from './Footer';
import NoMatch from './NoMatch';
import ScrollHandler from './components/ScrollHandler';
import './images/racetrack3.png';
import './App.css';
import { Switch, Route } from "react-router-dom";

function App() {
  return (
    <>
      <ScrollHandler />
      <Header />
      <main role="main" className="d-flex justify-content-center">
        <Switch>
          {/** <Route path="/bulkimport" exact component={BulkImport} /> **/}
          <Route path="/converter" exact component={Converter} />
          <Route path="/" exact component={Home} />
          <Route component={NoMatch} />
        </Switch>
      </main>
      <Footer />
    </>
  );
}

export default App;

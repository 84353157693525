import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

export default function Socials(props) {
  const socials = {
    instagram: { icon: faInstagram, url: 'https://www.instagram.com/daredevilmotorsports/' },
    facebook: { icon: faFacebook, url: 'https://www.facebook.com/Daredevilmotorsports/' },
    // yelp: {icon: faYelp, url: 'https://www.yelp.com/biz/daredevil-motorsports-sacramento'},
  }

  const socialItems = []
  Object.keys(socials).map(name => (
    socialItems.push(
      <Nav.Link className={props.className} href={socials[name]['url']} key={name} target="_blank">
        <FontAwesomeIcon icon={socials[name]['icon']} className={props.className} />
      </Nav.Link>
    )
  ))

  return (
    <>
      {socialItems}
    </>
  );
}

import { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// const ScrollHandler = ({ location, children }) => {
const ScrollHandler = ({ location }) => {
  useEffect(
    () => {
      const element = document.getElementById(location.hash.replace("#", ""));

      setTimeout(() => {
        window.scrollTo({
          behavior: element ? "smooth" : "auto",
          top: element ? element.offsetTop-75 : 0 
        });
      }, 100);
    }, [location]);

  // return children;
  return null;
};

ScrollHandler.propTypes = {
  // children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.any,
    key: PropTypes.string,
  }).isRequired
};

export default withRouter(ScrollHandler);

import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

function BasicServices(props) {
  return (
    <Col xs={12} md={4} lg={4} id={props.id} className="p-0 pt-3">
      <Card xs={12} className="m-2 border-top-0 border-bottom-0 rounded-0 align-content-center justify-content-center">
        <Card.Title className="text-center">
          <h3 className=''>
            <strong>{props.name}</strong>
          </h3>
        </Card.Title>
        {props.starting
          ? <Card.Subtitle className="text-secondary text-center">Starting At...</Card.Subtitle>
          : null}
        <Card.Body className="text-center">
          <h2><strong>${props.basePrice}</strong></h2>
          {props.noPlus
            ? <span className="">{props.noPlus}</span>
            : null}
          {props.plus
            ? <span><FontAwesomeIcon icon={faPlus} className="quote-icon" /> {props.plus}</span>
            : null}
          <ListGroup variant="flush">
            {Object.keys(props.addons).map(function(key) {
              return (
                <ListGroup.Item className="border-0 p-0 pt-2">
                  <strong>{key}</strong> {props.addons[key]}<br />
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Card.Body>
      </Card>
    </Col>
  );
}

const basicServices = [
  {
    name: 'Suspension Service',
    starting: true,
    basePrice: "275",
    addons: {
      "Fork Service": "$275 includes parts",
      "Shock Service": "$275 + parts",
      "Fork Removal and Installation": "$165",
      "Shock Removal and Installation": "$85+",
    },
  },
  {
    name: 'Steering Damper Service',
    starting: true,
    basePrice: "165",
    plus: "parts",
    addons: {
    },
  },
  {
    name: 'Flashtune ECU flashing',
    starting: true,
    basePrice: "350",
    noPlus: "For Offroad Only (No street bikes)",
    addons: {
      "Ecu Removal and Replacement": "$85+",
    },
  },
]

const basicServicesList = []

basicServices.map((srv, index) => (
  basicServicesList.push(
    <BasicServices key={index} name={srv.name} starting={srv.starting} basePrice={srv.basePrice} plus={srv.plus} noPlus={srv.noPlus} addons={srv.addons} />
  )))

function BasicPricing() {
  return (
    <>
      <Container>
        <Row className='d-flex justify-content-center'>
          <div>
            <h2 className='text-center'>
              <small>Some of</small><br />
              <strong>Our Services</strong>
            </h2>
          </div>
          <div className="w-100">
            <p className='text-center border-top border-bottom'>
              All prices are for <strong className='daredevil-red'>basic</strong> services and subject to change dependent on vehicle and condition.
            </p>
          </div>
        </Row>
      </Container>
      <div className="container-fluid w-75">
        <Row className="d-flex justify-content-center">
          {basicServicesList}
        </Row>
      </div>
    </>
  );
}

export default BasicPricing;

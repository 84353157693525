import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';

const StaticProductVariants = {
  "Vent/Vacuum Tubing": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Radiator Hose Kit — Standard": {
    "Option1 Name": "Color",
    "Option2 Name": "Make"
  },
  "Radiator Clamp Kit": {
    "Option1 Name": "Make"
  },
  "Radiator Hose Kit — OEM Design": {
    "Option1 Name": "Color",
    "Option2 Name": "Make"
  },
  "Radiator Hose Kit — Race Bike Design": {
    "Option1 Name": "Color",
    "Option2 Name": "Make"
  },
  "Radiator Hose Kit — Molded Y Configuration": {
    "Option1 Name": "Color",
    "Option2 Name": "Make"
  },
  "Radiator Hose Kit — Y Piece Race Design": {
    "Option1 Name": "Color",
    "Option2 Name": "Make"
  },
  "Lithium Ion Battery": {
    "Option1 Name": "Manufacturer Part Number"
  },
  "AGM Maintenance-Free Battery": {
    "Option1 Name": "Manufacturer Part Number"
  },
  "Factory-Activated AGM Maintenance-Free Battery": {
    "Option1 Name": "Manufacturer Part Number"
  },
  "Heavy-Duty Battery": {
    "Option1 Name": "Manufacturer Part Number"
  },
  "Conventional Battery Kit": {
    "Option1 Name": "Manufacturer Part Number"
  },
  "Heavy-Duty 12v Battery Kit": {
    "Option1 Name": "Manufacturer Part Number"
  },
  "GP Pro R4 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "SMX-2 Air Carbon V2 Leather Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Highlands Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "SP-5 Leather Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Booster Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "SP-2 V2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Faster Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "SMX-1 Air Carbon V2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Atom Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Mustang V2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Crossland Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Oscar Crazy Eight Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Shore Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Andes Touring Outdry® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Celer V2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "SP-1 V2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "SPX Air Carbon V2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "GPX V2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Copper Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Honda SMX-2 Air Carbon V2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Reef Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Gareth Leather Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Honda SP-8 V3 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Honda Copper Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Stella SMX-2 Air Carbon V2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Stella SMX-1 Air V2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Stella SP-2 V2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Stella Atom Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Stella Shore Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Women's Stella SP-1 V2 Leather Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Women's Stella SPX Air Carbon V2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Women's Stella Copper Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "GP Tech V2 S Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "GP Pro RS3 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Honda GP Pro R3 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Honda GP Plus R v2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "SMX-E Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "SP-365 Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Corozal V2 Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Andes V3 Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Honda SMX Z Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "SP-8 HDRY® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Denali Aerogel Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "HT-7 Heat Tech Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "HT-5 Heat Tech Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "HT-3 Heat Tech Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Hyde XT DrystarXF® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Bogota' DrystarXF® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Stella Vika V2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Stella C-1 Windstopper® V2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Stella Tourer W-7 Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Stella S-Max Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Supertech Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "GP Pro R3 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "GP Plus R v2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "GP Tech v2 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Morph Sport Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Halo Leather Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Morph Street Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "SP-8 V3 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Phenom Leather Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "SP-8 V3 Air Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Phenom Air Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "X Diesel AS-DSL Shotaro Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "X Diesel AS-DSL Kei Leather Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Chrome Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "GP-Air Leather Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Warden Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Honda Full Bore Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Youth Radar Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Techstar Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Youth Full Bore Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Thermo Shielder Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Full Bore XT Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Radar Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Full Bore Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Women's Stella Full Bore Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Women's Stella Reef Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Women's Stella SP-8 V3 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Stella Andes v3 Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Stella SR-3 V2 Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Stella Phenom Leather Air Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Valparaiso Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Apex Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "SP-Z Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Rage Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Primer Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "C-30 Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Café Divine Drystar® Leather Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Belize Outdry Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "C-1 V2 Gore-Tex® Windstopper® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Winter Surfer Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "T-SP W Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "C Vented Air Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Tourer W-6 Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "S-MAX Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Manufacturer Part Number"
  },
  "Apex V2 Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "WR-1 V2 Gore-Tex® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Drop 6.0 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Drop 4.0 Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Cirrus Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Aspen Pro Lite Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Youth Thermo Shielder Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Stella Tourer W-7 V2 Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "XT-5 Gore-Tex Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Tourer W-7 V2 Drystar® Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Techdura Gloves": {
    "Option1 Name": "Color",
    "Option2 Name": "Size"
  },
  "Tech-T Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SMX-1R V2 Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Tech 7 Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Tech 5 Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Tech 3 Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Tech 7 Enduro Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Tech 7 Enduro Drystar® Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Limited Edition Acumen Tech 10 Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Tech 10 Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Tech 10 Supervented Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Youth Tech 7S Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Youth Tech 3S Boots — 28": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Youth Tech 3S Boots — 29": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Youth Tech 3S Boots — 30,5": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Youth Tech 3S Boots — 32": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Youth Tech 3S Boots — 33": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Youth Tech 3S Boots — 34": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Youth Tech 3S Boots — 35,5": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Youth Tech 3S Boots — 37": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Youth Tech 3S Boots — 38": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Youth Tech 3S Boots — 39,5": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Youth Tech 3S Boots — 40,5": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Youth Tech 3S Boots — 42": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Tech Boots Buckle Base": {
    "Option1 Name": "Color",
    "Option2 Name": null,
    "Option3 Name": "Manufacturer Part Number"
  },
  "Boot Ratchet for Supertech R Boots": {
    "Option1 Name": "Manufacturer Part Number"
  },
  "Stella SMX-1R V2 Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SMX1-R V2 Vented Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Stella SMX-1 R V2 Vented Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Supertech R Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SMX-6 v2 Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Ridge Waterproof Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "RT-8 Gore-Tex Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "EU Size"
  },
  "SMX-6 v2 Vented Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SMX-6 v2 Drystar® Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Stella SMX-6 v2 Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Stella SMX-6 v2 Vented Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SMX-6 v2 Gore-Tex® Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SMX S Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SMX-S Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SMX Plus Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SMX Plus Vented Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Corozal Adventure Drystar® Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "US Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Belize Drystar® Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Corozal Adventure Drystar® Oiled Leather Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Tech 3 Enduro Boots": {
    "Option1 Name": "Size",
    "Option2 Name": "Manufacturer Part Number"
  },
  "Stella Tech 3 Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Limited Edition Ember Tech 10 Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "RT-7 Drystar® Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Oscar Monty Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Tech 7S Youth Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SP-X BOA Boots — 38": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SP-X BOA Boots — 39": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SP-X BOA Boots — 40": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SP-X BOA Boots — 41": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SP-X BOA Boots — 42": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SP-X BOA Boots — 43": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SP-X BOA Boots — 44": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SP-X BOA Boots — 45": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SP-X BOA Boots — 46": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SP-X BOA Boots — 47": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "SP-X BOA Boots — 48": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Stella RT-7 Drystar® Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "XT-8 Gore-Tex® Boots — 42": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "XT-8 Gore-Tex® Boots — 43": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "XT-8 Gore-Tex® Boots — 44": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "XT-8 Gore-Tex® Boots — 45": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "XT-8 Gore-Tex® Boots — 46": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "XT-8 Gore-Tex® Boots — 47": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "XT-8 Gore-Tex® Boots — 48": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "XT-8 Gore-Tex® Boots — 40": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "XT-8 Gore-Tex® Boots — 41": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Toucan Gore-Tex® Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Web Gore-Tex® Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Vika v2 Waterproof Women's Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Supertech V Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Stella Valencia Waterproof Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Air Plus v2 Gore-Tex® XCR Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Radon Drystar® Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Andes v2 Drystar® Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Tech 3 Enduro Waterproof Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Limited Edition Vision Tech 10 Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Limited Edition Squad '23 Tech 10 Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Limited Edition 'Laser' Tech 10 Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  },
  "Turnstone Boots": {
    "Option1 Name": "Color",
    "Option2 Name": "Size",
    "Option3 Name": "Manufacturer Part Number"
  }
};

function getPartDataByField(element, fname) {
  var data = element.getElementsByTagName(fname)[0];
  // console.log(`data for ${fname} was ${data}`);
  // console.log(`text for ${fname} was ${data.textContent}`);
  return data.textContent;
}

function getPartBullets(element) {
  var output = '"<p>';
  for (var i = 1; i < 25; i++) {
    var data = element.getElementsByTagName(`bullet${i}`)[0];
    if (data.textContent.length > 0) {
      output += `${data.textContent}<br>`;
    }
  }
  output += '</p>"';
  return output;
}

function generateVariantOptions(productsCache, productName, partDescription, manufacturerPartNumber) {
  var options = {
  }
  if (partDescription === "Radiator Hose Kit - Blue - Suzuki") {
    console.log(`${partDescription} - ${productName} - ${manufacturerPartNumber}`);
  }

  var fields = partDescription.split(' - ');
  fields.shift();
  if (fields.length >= 0) {
    if (!productsCache[productName]) {
      productsCache[productName] = {};
      if (fields.length > 0) {
        alert(`${fields.length} possible variants detected for '${productName}': ${fields.join(', ')}`);
        for (var i = 0; i < fields.length; i++) {
          let variantName = prompt(`Please name the variant type("${fields[i]}") for "${productName}": `, 'Color');
          let optName = `Option${i + 1} Name`;
          productsCache[productName][optName] = variantName;
        }
      }
    }

    console.log(`if ${Object.keys(productsCache[productName]).length} is less than 3 or greater than 0 and not in keys [${Object.keys(productsCache[productName])}] - ${!Object.keys(productsCache[productName]).includes("Manufacturer Part Number")}?`);
    if (Object.keys(productsCache[productName]).length < 3 && Object.keys(productsCache[productName]).length >= 0) {
      var names = [];
      Object.values(productsCache[productName]).forEach((v) => {
        names.push(v);
      });
      if (!Object.values(names).includes("Manufacturer Part Number")) {
        console.log(`we should be creating the MPN Option`);
        let optName = `Option${Object.keys(productsCache[productName]).length + 1} Name`;
        productsCache[productName][optName] = "Manufacturer Part Number"
      }
    }

    if (partDescription === "Radiator Hose Kit - Blue - Suzuki") {
      console.log(productsCache[productName]);
    }

    // for (var i = 0; i < Object.keys(productsCache[productName]).length; i++) {
    for (var key in Object.keys(productsCache[productName])) {
      let i = parseInt(key.split()[0].slice(-1)[0]) + 1;
      let optName = `Option${i} Name`;
      let optValue = `Option${i} Value`;
      options[optName] = productsCache[productName][optName];
      console.log(`key: ${key}, optname: ${optName}`);
      if (options[optName] === "Manufacturer Part Number") {
        console.log(`Option Name was MPN: ${optName} = ${options[optName]}`);
        options[optValue] = manufacturerPartNumber;
      } else {
        options[optValue] = fields[i - 1];
      }
    }

    if (partDescription === "Radiator Hose Kit - Blue - Suzuki") {
      console.log(options);
    }

    options['Title'] = productName;
  }

  return options;
}

const headerKeys = [
  'Handle',
  'Title',
  'Body (HTML)',
  'Vendor',
  'Product Category',
  'Type',
  'Tags',
  'Published',
  'Option1 Name',
  'Option1 Value',
  'Option2 Name',
  'Option2 Value',
  'Option3 Name',
  'Option3 Value',
  'Variant SKU',
  'Variant Grams',
  'Variant Inventory Tracker',
  'Variant Inventory Qty',
  'Variant Inventory Policy',
  'Variant Fulfillment Service',
  'Variant Price',
  'Variant Compare At Price',
  'Variant Requires Shipping',
  'Variant Taxable',
  'Variant Barcode',
  'Image Src',
  'Image Position',
  'Image Alt Text',
  'Gift Card',
  'SEO Title',
  'SEO Description',
  'Google Shopping / Google Product Category',
  'Google Shopping / Gender',
  'Google Shopping / Age Group',
  'Google Shopping / MPN',
  'Google Shopping / Condition',
  'Google Shopping / Custom Product',
  'Google Shopping / Custom Label 0',
  'Google Shopping / Custom Label 1',
  'Google Shopping / Custom Label 2',
  'Google Shopping / Custom Label 3',
  'Google Shopping / Custom Label 4',
  'Variant Image',
  'Variant Weight Unit',
  'Variant Tax Code',
  'Cost per item',
  'Included / United States',
  'Price / United States',
  'Compare At Price / United States',
  'Included / International',
  'Price / International',
  'Compare At Price / International',
  'Status'
]

const headers = {
  'Handle': 'productName',
  'Title': 'partDescription',
  'Body (HTML)': '',
  'Vendor': 'brandName',
  'Product Category': '',
  'Type': 'productName', // TODO: productCategory via split of productName
  'Tags': 'partDescription',
  'Published': 'TRUE',
  'Option1 Name': 'Title',
  'Option1 Value': 'Default Title',
  'Option2 Name': '',
  'Option2 Value': '',
  'Option3 Name': '',
  'Option3 Value': '',
  'Variant SKU': 'supplierNumber',
  'Variant Grams': '0.0',
  'Variant Inventory Tracker': 'shopify',
  'Variant Inventory Qty': '0',
  'Variant Inventory Policy': 'deny',
  'Variant Fulfillment Service': 'manual',
  'Variant Price': 'baseRetailPrice', // TODO: find which to use this or originalRetailPrice
  'Variant Compare At Price': '', // TODO USE which is higher?
  'Variant Requires Shipping': 'TRUE',
  'Variant Taxable': 'TRUE',
  'Variant Barcode': '',
  // 'Image Src': 'productImage',
  'Image Src': '',
  'Image Position': '',
  // 'Image Alt Text': 'partDescription',
  'Image Alt Text': '',
  'Gift Card': 'FALSE',
  'SEO Title': '',
  'SEO Description': '',
  'Google Shopping / Google Product Category': '',
  'Google Shopping / Gender': '',
  'Google Shopping / Age Group': '',
  'Google Shopping / MPN': '',
  'Google Shopping / AdWords Grouping': '',
  'Google Shopping / AdWords Labels': '',
  'Google Shopping / Condition': '',
  'Google Shopping / Custom Product': '',
  'Google Shopping / Custom Label 0': '',
  'Google Shopping / Custom Label 1': '',
  'Google Shopping / Custom Label 2': '',
  'Google Shopping / Custom Label 3': '',
  'Google Shopping / Custom Label 4': '',
  // 'Variant Image': 'partImage',
  'Variant Image': '',
  'Variant Weight Unit': 'lb',
  'Variant Tax Code': '',
  'Cost per item': 'yourDealerPrice',
  'Included / United States': 'TRUE',
  'Price / United States': '',
  'Compare At Price / United States': '',
  'Included / International': 'FALSE',
  'Price / International': '',
  'Compare At Price / International': '',
  'Status': 'draft',
}

// const alwaysCols = [
//   'Handle',
//   'Option1 Value',
//   'Option2 Value',
//   'Option3 Value',
// ]

// const parentCols = [
//   'Title',
//   'Body (HTML)',
//   'Vendor',
//   'Product Category',
//   'Type',
//   'Tags',
//   'Published',
//   'Option1 Name',
//   'Option2 Name',
//   'Option3 Name',
// ]

// function createVariantParentColumnString(createVariantParent, colValue, punctuation) {
//   if (createVariantParent) {
//     //   if (parentCols.includes(colName) || alwaysCols.includes(colName)) {
//     return `${colValue}${punctuation}`;
//     //   } else {
//     //     parentStr += `${punctuation}`;
//     //   }
//   }
//   return '';
// }

function createColumnString(colValue, punctuation) {
  // if (notVariant) {
  // varStr += `${colValue}${punctuation}`;
  return `${colValue}${punctuation}`;
  // } else {
  //   if (!parentCols.includes(colName) || alwaysCols.includes(colName)) {
  //     varStr += `${colValue}${punctuation}`;
  //   } else {
  //     varStr += `${punctuation}`;
  //   }
  // }
}

function convertXMLToCSV(xml) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(xml, "application/xml");
  const errorNode = doc.querySelector("error parsing XML document");
  if (errorNode) {
    alert("error parsing XML file, verify file is XML or call me :)");
    return "";
  }

  var firstLine = headerKeys.join(',') + '\n';
  var baseVariants = '';
  var output = '';
  var root = doc.getElementsByTagName('root')[0];
  var productsCache = {};
  var variantsCache = {};
  if (window.confirm("Do you want to use existing variants (if they exist) for this product? (Ok/Cancel)")) {
    productsCache = StaticProductVariants;
  }

  let productFilter = prompt(`Please type the string you'd like to use to filter products for bulk import: `, 'Case Sensitive String');

  // console.log(`root is ${root}`);
  // console.log(`rootChildNodes is ${root.childNodes}`);
  // console.log(`rootChildElementCount is ${root.childElementCount}`);
  for (let i = 0; i < root.childElementCount; i++) {
    var element = root.children.item(i);


    var filterRegX = new RegExp(productFilter);
    if (!getPartDataByField(element, 'productName').match(filterRegX)) {
      continue;
    }

    // console.log(`element ${i} is ${element.localName}`);
    var tmpStr = "";
    // var parentStr = "";
    var variants = {}
    // var createVariantParent = false;
    // var notVariant = true;
    for (const key of headerKeys) {
      var value = headers[key];
      // console.log(`key: ${key} |value: ${value}`);
      if (key === 'Body (HTML)') {
        // parentStr += createVariantParentColumnString(createVariantParent, getPartBullets(element), ',');
        tmpStr += createColumnString(getPartBullets(element), ',');
      } else if (key.match(/Option\d (Name|Value)/)) {
        var tmp = '';
        // var ptmp = '';
        if (variants[key]) {
          // if (key.includes('Value')) {
          //   ptmp = variants[key.replace(/Value/, 'Name')];
          // } else {
          //   ptmp = variants[key];
          // }
          // ptmp = variants[key];
          tmp = variants[key];
        } else {
          // ptmp = headers[key];
          tmp = headers[key];
        }
        // parentStr += createVariantParentColumnString(createVariantParent, ptmp, ',');
        tmpStr += createColumnString(tmp, ',');
      } else if (value.match(/(TRUE|FALSE)/)) {
        tmpStr += createColumnString(value, ',');
        // parentStr += createVariantParentColumnString(createVariantParent, value, ',');
      } else if (key.match(/Variant (Weight Unit|Inventory Qty|Inventory Policy|Fulfillment Service|Grams)/)) {
        tmpStr += createColumnString(value, ',');
        // parentStr += createVariantParentColumnString(createVariantParent, value, ',');
      } else if (value === '') {
        tmpStr += createColumnString(value, ',');
        // parentStr += createVariantParentColumnString(createVariantParent, value, ',');
      } else if (key === 'Status') {
        tmpStr += createColumnString(value, '\n');
        // parentStr += createVariantParentColumnString(createVariantParent, value, '\n');
      } else if (key === 'Title') {
        if (variants[key]) {
          tmpStr += createColumnString(variants[key], ',');
          // parentStr += createVariantParentColumnString(createVariantParent, variants[key], ',');
        } else {
          tmpStr += createColumnString(getPartDataByField(element, value), ',');
        }
      } else {
        var fieldData = getPartDataByField(element, value);
        if (key === 'Handle') {
          variants = generateVariantOptions(productsCache, fieldData, getPartDataByField(element, 'partDescription'), getPartDataByField(element, 'supplierNumber'));
          // if (variants['Title'] && variantsCache[variants['Title']]) {
          //   notVariant = false;
          // } else if (variants['Title'] && !variantsCache[variants['Title']]) {
          if (variants['Title'] && !variantsCache[variants['Title']]) {
            // createVariantParent = true;
            variantsCache[variants['Title']] = [];
            // notVariant = false;
          }
          fieldData = fieldData.replace(/[^a-zA-Z0-9]/g, '-').replace(/\s/g, '-').replace(/-+/g, '-');
          // } else if ( key === 'Type' ) {
          //   fieldData = fieldData.split(' - ')[0];
        } else if (key === 'Tags') {
          fieldData = `"${fieldData.split(' - ').join(',')}"`;
        } else if (key === 'Variant Image') {
          fieldData = `"${fieldData}.png"`;
        } else if (key === 'Image Src') {
          fieldData = `"${fieldData}.png"`;
        }
        tmpStr += createColumnString(fieldData, ',');
        // parentStr += createVariantParentColumnString(createVariantParent, fieldData, ',');
      }
    }
    // baseVariants += parentStr;
    // console.log(parentStr);
    if (!variantsCache[variants['Title']]) {
      variantsCache[variants['Title']] = [];
    }
    variantsCache[variants['Title']].push(tmpStr);
  }

  console.log(productsCache);
  for (const k of Object.keys(variantsCache)) {
    for (const line of variantsCache[k]) {
      output += line;
    }
  }
  return [firstLine + baseVariants + output, productsCache];
}

class Converter extends Component {
  showFile = async (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      // console.log(text)
      // alert(text)
      let [csvData, jsonData] = convertXMLToCSV(text);
      const element = document.createElement("a");
      const output = new Blob([csvData], { type: 'text/plain' });
      alert("save shopify_import_data.csv and import into shopify");
      element.href = URL.createObjectURL(output);
      element.download = "shopify_import_data.csv";
      document.body.appendChild(element);
      element.click();
      alert("save the next file and email to jay for consistent pre-configured variations");
      const element2 = document.createElement("a");
      const output2 = new Blob([JSON.stringify(jsonData)], { type: 'application/json' });
      element2.href = URL.createObjectURL(output2);
      element2.download = "product_variations_cache.json";
      document.body.appendChild(element2);
      element2.click();
    };
    reader.readAsText(e.target.files[0])
  }

  render = () => {
    return (
      <div className="container-fluid p-0 d-block justify-content-center align-content-center">
        <div className="d-block marketing p-2 pt-5 pb-4 m-0">
          <Container>
            <div>
              <input type="file" onChange={(e) => this.showFile(e)} />
            </div>
          </Container>
        </div>
      </div>
    )
  }
}

export default Converter;

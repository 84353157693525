import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faWrench, faFlagCheckered, faDotCircle, faHandPaper, faUserTie, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

function Services(props) {
  return (
    <Col xs={12} md={6} lg={4} id={props.id} className=" p-0 pt-3">
      <Row noGutters className="p-0 pr-1 pl-1">
        <Col className="service w-75 service-back-icon">
          <FontAwesomeIcon icon={props.icon} className="service-back-icon service-icon" />
        </Col>
        <Col sm={12} md={12} lg={12} className="service-front-text">
          <Row className="m-0">
            <FontAwesomeIcon icon={props.icon} className="service-front-icon service-icon m-2" />
            <h4 className='text-left'>
              {props.name}<br />
              <small>{props.subtitle}</small>
            </h4>
          </Row>
          <p className='text-left'>
            {props.description}
            <strong> {props.strong} </strong>
          </p>
        </Col>
      </Row>
    </Col>
  );
}

const introServices = [
  {
    name: 'Full Service Suspension',
    subtitle: 'Sales, Service, Repair, Tuning',
    icon: faCogs,
    description: 'Our Ohlins and K-Tech Certified Technician has the knowledge and experience to help you maximize your ride.  We sell and service Ohlins, K-Tech, Andreani, Nitron, Bitubo and OEM forks, fork cartridges, shocks, and steering dampers.  We can improve grip and handling through the installation of custom valving, springs and chassis geometry tuning.'
  },
  {
    name: 'Race and Trackday Prep',
    subtitle: 'AFM and MotoAmerica',
    icon: faFlagCheckered,
    description: 'Our many years of racing experience can help you get your bike ready for the track, whether it be racing or track days. We provide engine performance tuning, suspension upgrades and tuning, as well as general track bike services.  We build, repair and upgrade track/race bikes.'
  },
  {
    name: 'Tires and Wheels',
    subtitle: 'Sales and Installation',
    icon: faDotCircle,
    description: 'We keep Pirelli and Michelin race tires in stock as well as a variety of DOT street tires to suit all your motorcycle needs. We sell a variety of aftermarket wheels. OZ, BST, CoreMoto to name a few. We do not service cruisers or Harley Davidson.',
    strong: 'OFF BIKE TIRE MOUNTING ONLY.',
  },
  {
    name: 'Gear & Accessories',
    subtitle: 'Suits, Helmets, Gloves and More',
    icon: faShoppingCart,
    description: 'We sell all the gear and clothing needed to get you ready whether it\'s for the street, trackdays or racing.'
  },
  {
    name: 'Brakes',
    subtitle: 'Stopping Power',
    icon: faHandPaper,
    description: 'We have in stock a variety of Brembo and Galespeed front and rear brake Master Cylinders. We keep EBC, Vesrah, SBS and other high performance brake pads in stock for most bikes.'
  },
  {
    name: 'Installations',
    subtitle: 'Accessories and Parts',
    icon: faWrench,
    description: 'We use the best aftermarket products in the business to ensure quality, durability and the highest performance.  We only install race proven products.'
  },
  {
    name: 'Mithos Fitting Center',
    subtitle: 'Custom Suits',
    icon: faUserTie,
    description: 'We are an authorized Mithos fitting center.'
  }
]

const introServicesList = []

introServices.map((srv, index) => (
  introServicesList.push(
    <Services key={index} icon={srv.icon} name={srv.name} subtitle={srv.subtitle} description={srv.description} strong={srv.strong} />
  )))

function FullServices() {
  return (
    <>
      <Container>
        <Row className='d-flex justify-content-center'>
          <h2 className='text-center'>
            <small>What We Do</small><br />
            <strong>Our Services</strong>
          </h2>
        </Row>
      </Container>
      <Container className="">
        <Row className="d-flex justify-content-center">
          {introServicesList}
        </Row>
      </Container>
    </>
  );
}

export default FullServices;

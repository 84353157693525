import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export default function Home() {
  return (
  <>
    <div className="container-fluid p-0 d-block justify-content-center align-content-center">
      <div className="d-block marketing p-2 pt-5 pb-4 m-0">
        <Container>
          <Row className='d-flex justify-content-center'>
              <h2 className='text-center'>
                Page Not Found
              </h2>
          </Row>
        </Container>
      </div>
    </div>
  </>
  );
}

import React from "react";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Socials from './components/Socials';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';

export default function Footer() {
  const links = [
    'Services',
    'Pricing',
    'Contact',
    'About',
  ]

  const linkItems = []

  links.map((name, _) => (
    linkItems.push(
      <LinkContainer to={"#" + name} key={name}>
        <Nav.Link className="text-dark">{name}</Nav.Link>
      </LinkContainer>
    )
  ))

  return (
    <footer>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3115.931073172013!2d-121.36058828448128!3d38.65046516906645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809ad933bf37c3d5%3A0x3c34b05c8588921b!2sDaredevil%20Motorsports!5e0!3m2!1sen!2sus!4v1566897764057!5m2!1sen!2sus" width={"100%"} height={"350"} frameBorder={"0"} border={"0"} style={{ border: 0 }} allowFullScreen={""} title="Daredevil Google Maps Location"></iframe>

      <div id="Pricing" className="d-block footer mt-0 pt-5 pb-5">
        <Container>
          <Row className='d-flex justify-content-center'>
            <Col xs={12} className="d-flex text-center align-content-center justify-content-center">
              <Navbar collapseOnSelect expand="lg" className="border-bottom border-dark" sticky="top">
                <Socials className="daredevil-red footer-icon" />
              </Navbar>
            </Col>
            <Col xs={12}>
              <h4 className='text-white text-center'>
                <small className='text-muted'>Ride with the Devil!</small><br />
                <strong>Daredevil Motorsports</strong>
              </h4>
            </Col>
            <Col xs={12} className="d-flex text-center align-content-center justify-content-center">
              <Navbar collapseOnSelect expand="lg" className="border-top border-dark" sticky="top">
                <Nav className="mr-auto text-muted">
                  Copyright ©{new Date().getFullYear()} Daredevil Motorsports. All rights Reserved.
                </Nav>
                <Nav className="mr-auto text-muted">
                  <LinkContainer to="/" key="home">
                    <Nav.Link className="text-dark">Home</Nav.Link>
                  </LinkContainer>
                  <Nav.Link href="https://daredevilmotorsports.store/" className="text-dark">
                    Online Store
                  </Nav.Link>
                  {linkItems}
                </Nav>
              </Navbar>
            </Col>
          </Row>
        </Container>
        <div className="container-fluid w-75">
          <Row className="d-flex justify-content-center">
          </Row>
        </div>
      </div>

    </footer>
  );
}

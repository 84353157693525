import React from 'react';
import logo from './images/daredevil_logo_h.svg';
import Socials from './components/Socials';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';

export default function Header() {

  const links = [
    'Services',
    'Pricing',
    'Contact',
    'About',
  ]

  const linkItems = []

  links.map((name, index) => (
    linkItems.push(
      <LinkContainer to={"/#" + name} key={name}>
        <Nav.Link className="text-dark">{name}</Nav.Link>
      </LinkContainer>
    )
  ))

  return (
    <React.Fragment>
      <Navbar collapseOnSelect expand="lg" className="daredevil-red-bg border-bottom border-dark shadow-lg" sticky="top">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <LinkContainer to="/">
              <Navbar.Brand>
                Home
              </Navbar.Brand>
            </LinkContainer>
            {linkItems}
            <Nav.Link href="https://daredevilmotorsports.store/" className="nav-link-bright">
              Online Store
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Nav.Link href="tel:9163334730">
          <Button variant="dark">
            <FontAwesomeIcon icon={faPhoneVolume} style={{ fontSize: "1.25rem" }} /> &nbsp; Call Us
          </Button>
        </Nav.Link>
        <Nav.Link href="mailto:daredevilmotors@yahoo.com">
          <Button variant="dark">
            <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: "1.25rem" }} /> &nbsp; Email Us
          </Button>
        </Nav.Link>
        <Socials className="text-dark nav-icon" />
      </Navbar>
      <header>
        <Container className="d-block justify-content-center align-content-center mt-3">
          <img src={logo} alt="logo" className="" />
        </Container>
      </header>
    </React.Fragment >
  );
}

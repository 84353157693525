import React from 'react';
import BasicPricing from './components/BasicPricing';
import Instafeed from './components/Instafeed';
import FullServices from './components/FullServices';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faEnvelope, faPhoneVolume, faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

export default function Home() {
  return (
    <>
      <div className="container-fluid p-0 d-block justify-content-center align-content-center">
        <div className="d-block marketing p-2 pt-5 pb-4 m-0">
          <Container>
            <Row className='d-flex justify-content-center'>
              <h2 className='text-center'>
                Sacramento's Premier Motorcycle Suspension and Race Shop
              </h2>
              <p className='text-center'>
                DareDevil  is a Sacramento based motorcycle race shop proudly <strong className='daredevil-red'>specializing</strong> in suspension services for track, street and dirt as well as race/trackday builds. Whether you're a trackday junkie or fighting for podiums, we have the parts, gear and expertise to help you achieve your goals.
              </p>
            </Row>
          </Container>
        </div>
        <div className="d-block spacer">
          <Instafeed />
        </div>
        <div id="Services" className="d-block marketing pt-5 pb-4">
          <FullServices />
        </div>
        <div id="Contact" className="d-block spacer p-0">
          <Container className="d-flex align-content-center justify-content-center p-0">
            <Col xs={11} lg={{ span: 7 }} className="m-0 p-1 call-us">
              <Row>
                <Col xs={12} className="mb-4 pt-3 text-center">
                  <Nav.Link href="tel:9163334730" className="daredevil-red">
                    <Button variant="dark" className="call-us-button">
                      <h1><strong><FontAwesomeIcon icon={faPhoneVolume} /> 916-333-4730</strong></h1>
                    </Button>
                  </Nav.Link>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mb-4 text-center">
                  <Nav.Link href="mailto:daredevilmotors@yahoo.com" className="daredevil-red">
                    <Button variant="dark" className="call-us-button">
                      <h3><strong><FontAwesomeIcon icon={faEnvelope} /> daredevilmotors<FontAwesomeIcon icon={faAt} className="daredevil-red" />yahoo.com</strong></h3>
                    </Button>
                  </Nav.Link>
                </Col>
              </Row>
              <Row className="pb-2">
                <Col xs={12} md={6} lg={6} className="text-center">
                  <address>
                    <h4 className="text-white">
                      <small>4555 Auburn Blvd Suite 12</small><br />
                      <strong className="daredevil-red">Sacramento, CA</strong>
                    </h4>
                  </address>
                </Col>
                <Col xs={12} md={6} lg={6} className="text-center">
                  <h4 className="text-white">
                    <strong className="">Hours</strong><br />
                    <small>
                      <span className="daredevil-red">Tuesday</span> Closed - By Appointment Only<br />
                      <span className="daredevil-red">Wednesday</span> 9 am - 5 pm<br />
                      <span className="daredevil-red">Thursday</span> 9 am - 5 pm<br />
                      <span className="daredevil-red">Friday</span> 9 am - 5 pm<br />
                      <span className="daredevil-red">Saturday</span> 10am - 4pm
                    </small>
                  </h4>
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
        <div id="Pricing" className="d-block marketing pt-5 pb-4">
          <BasicPricing />
        </div>
        <div id="About" className="d-block spacer p-2 mt-4 mb-4">
          <Container>
            <Row className='m-1'>
              <Card>
                <Card.Body>
                  <blockquote className="blockquote mb-0">
                    <p>
                      <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon" />
                      Damion Victor and I purchased and took over operations of Performance Cycle on Jan 1st 2016, rechristening it Daredevil Motorsports. Together we have 40 years of combined mechanical experience and many years of racing competitively, so we have what it takes to handle any project. We strive to provide great customer service at an affordable price. We are a racing and track preparation service facility that services all major brands of sportbikes that specializes in suspension and setup.
                      <FontAwesomeIcon icon={faQuoteRight} className="quote-icon" />
                    </p>
                    <footer className='text-monospace blockquote-footer text-right'>
                      Jesus Sanjurjo <Badge pill variant="secondary"><strong>Owner</strong></Badge>
                    </footer>
                  </blockquote>
                </Card.Body>
              </Card>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

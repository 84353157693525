import React, { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import igfeed from '../blobs/feed.json';

function Instafeed() {
  const [data, setData] = useState({media:[]});

  const enableFeed = true;

  useEffect(() => {
    const fetchData = async () => {
      let media = []
      media = igfeed.postIDs.reduce((result, postID) => {
        let node = igfeed.posts[postID].node;
        result.push({
          id: node.id,
          thumbnail: `${process.env.PUBLIC_URL}/igfeed/${postID}.jpg`,
          likes: ( node.edge_liked_by ) ? node.edge_liked_by.count : '',
          caption: ( node.edge_media_to_caption.edges.length ) ? node.edge_media_to_caption.edges[0].node.text : '',
          commentsNumber: node.edge_media_to_comment.count,
          accessibilityCaption: node.accessibility_caption,
          dimension: node.dimensions,
          postLink: `https://www.instagram.com/p/${node.shortcode}/`,
        });
        return result;
      }, media);

      setData({media: media});
    };

    fetchData();
  }, []);

  return(
    <>
      <Row className="m-0 p-0">
      {enableFeed && data.media.map(item => (
        <Col id={item.id} key={item.id} xs={{span: 3 }} md={{span: 4}} lg={{span: 3 }} xl={{span: 2 }} className="d-flex p-0 m-0 ig-image">
          <Nav.Link href={item.postLink} className="p-0 m-0">
            <div className="m-0 p-0">
            <Image src={item.thumbnail} fluid alt={item.accessibilityCaption} />
            </div>
            <div className="m-0 p-4 ig-overlay">
              <Col xs={12} className="pb-2">
                <span>
                  <FontAwesomeIcon icon={faHeart} className="daredevil-red" /> {item.likes}
                </span>
                <span className="float-right">
                  <span className="daredevil-red">{item.commentsNumber}</span> <small>Comments</small>
                </span>
              </Col>
              <Col xs={0} md={12} className="d-flex align-bottom">
                <span className="d-none d-sm-block">
                  {item.caption}
                </span>
              </Col>
            </div>
          </Nav.Link>
        </Col>
      ))}
      </Row>
    </>
  );
}

export default Instafeed;
